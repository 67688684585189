import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import PartnerBenefits from "components/ui/extended/PartnerBenefits";
import PartnerSteps from "components/ui/extended/PartnerSteps";
import Customers from "components/ui/extended/Customers";
import AgenciesForm from "components/ui/extended/AgenciesForm";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";

export interface AgenciesProps {
  location: Location;
}

export default ({ location }: AgenciesProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location} withCta={false}>
      <SEO
        title={t("pages.agencies.seo.title")}
        description={t("pages.agencies.seo.description")}
        image={{
          relativePath: "meta/digital-marketing-agencies-growth.jpg",
          alt: "Customerly Agencies",
        }}
      />
      <PlainHeader
        title={t("pages.agencies.title")}
        description={t("pages.agencies.description")}
        titleMaxWidth={800}
        descriptionMaxWidth={700}
        ctaButton={{
          show: true,
          text: t("pages.agencies.cta"),
          icon: "arrow-right",
          path: "#partnerForm",
        }}
      />

      <PartnerBenefits />

      <PartnerSteps />

      <TestimonialWithImageFrancesca />

      <AgenciesForm />

      <Customers />
    </GlobalWrapper>
  );
};
